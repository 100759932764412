import { createContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { AccountApi, AccountBranding, CustomerApi, PortalCustomerModel, SignInPageViewModel } from "../apiclient";
import { apiConfig } from "../config";
import SignInPage from "../pages/Account/SignInPage";

export interface SignInProps {
    authenticated: boolean,
    account: SignInPageViewModel | null,
    customer: PortalCustomerModel | null,
    refreshCustomer: () => void,
    signOut: () => void
}
const initialState: SignInProps = {
    authenticated: false,
    account: null,
    customer: null,
    refreshCustomer: () => {

    },
    signOut: () => {

    }
}

const AuthContext = createContext(initialState);

function AuthProvider({ children }: any) {
    let customerApi = new CustomerApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );
    let accountApi = new AccountApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );
    const [loading, setLoading] = useState(false);
    const ApplyDynamicStyle = (styles: AccountBranding) => {
        let font = styles.primaryFont;
        let fontUrl = styles.primaryFontUrl;
        if (fontUrl == null) fontUrl = "";
        if (fontUrl.length > 0) {
            const fontWidget = document.createElement('link');
            fontWidget.rel = 'stylesheet';
            fontWidget.href = fontUrl;
            document.head.appendChild(fontWidget);
        }

        const styleWidget = document.createElement('style');
        document.head.appendChild(styleWidget);

        if (font) {
            styleWidget.sheet?.insertRule(`body {font-family:${font} !important}`);
        }

        const hextoRgb = (hex: string) => {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        }

        styleWidget.sheet?.insertRule(`.btn-orange {background-color: ${styles.buttonBackgroundColor}; color: ${styles.buttonTextColor}}`, 0);
        styleWidget.sheet?.insertRule(`.btn-orange:hover {background-color: ${styles.buttonSelectedBackgroundColor}}`, 0);
        styleWidget.sheet?.insertRule(`.btn-blue {background-color: ${styles.buttonSelectedBackgroundColor}; color: ${styles.buttonTextColor}}`, 0);
        styleWidget.sheet?.insertRule(`.btn-blue:hover {background-color: ${styles.buttonSelectedBackgroundColor};filter: brightness(80%)}`, 0);
        styleWidget.sheet?.insertRule(`.my_card_box {background-color: ${styles.bannerBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.my_card_text {background-color: ${styles.bannerBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.my_card_box .btn:hover {background-color: ${styles.bannerBackgroundColor}; color:${styles.buttonTextColor}; border-color:${styles.buttonTextColor}}`, 0);
        styleWidget.sheet?.insertRule(`.footer ul li.active a {background-color: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.footer ul li a:hover {background-color: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.footer ul li a:focus {background-color: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.nav_bg nav .inner_nav>ul>li {background-color: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.nav-icon:hover > span, .nav-icon:focus > span{background: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.nav-icon.ml-auto.open.change > span{background: ${styles.buttonSelectedBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.reward_text{background: ${styles.bannerBackgroundColor};`, 0);
        styleWidget.sheet?.insertRule(`.available_redeem ul li .redeem_icon{background: ${styles.bannerBackgroundColor};`, 0);

        if (styles.buttonBackgroundColor) {
            let backgroundRgb = hextoRgb(styles.buttonBackgroundColor);
            if (backgroundRgb) {
                let backgroundWithOpacity = `rgba(${backgroundRgb.r}, ${backgroundRgb.g}, ${backgroundRgb.b}, 0.2)`;
                styleWidget.sheet?.insertRule(`.points_box em{background: ${backgroundWithOpacity};`, 0);
            }
        }
    }

    const refreshCustomer = () => {
        if (!loading) {
            setLoading(true);
            if (!state.account) {
                accountApi.accountSignInGet()
                    .then(account => {
                        customerApi.customerGet()
                            .then(response => {
                                if (account.data.accountBranding) ApplyDynamicStyle(account.data.accountBranding)
                                setState({ ...state, account: account.data, authenticated: true, customer: response.data })
                                setLoading(false);
                            }).catch((error: any) => {
                                if (error.response?.status === 401) {
                                    setState({ ...state, account: account.data, authenticated: false, customer: null })
                                }
                                else if (error.response?.status === 404) {
                                    setState({ ...state, account: account.data, authenticated: true, customer: null })
                                }
                                setLoading(false);
                            })
                    }).catch((error: any) => {
                        setLoading(false)
                    })
            } else {
                customerApi.customerGet()
                    .then(response => {
                        setState({ ...state, authenticated: true, customer: response.data })
                        setLoading(false);
                    }).catch((error: any) => {
                        if (error.response?.status === 401) {
                            setState({ ...state, authenticated: false, customer: null })
                        }
                        else if (error.response?.status === 404) {
                            setState({ ...state, authenticated: true, customer: null })
                        }
                        setLoading(false);
                    })
            }

        }
    }

    const signOut = () => {
        window.location.href = '/account/logout'
    }

    const [state, setState] = useState({ ...initialState, signOut: signOut, refreshCustomer: refreshCustomer });


    useEffect(() => {
        const initialize = () => {
            if (!state.customer) {
                refreshCustomer()
            }
        }
        initialize();
    }, [state]);


    return (
        <AuthContext.Provider value={{ ...state }}>
            {loading ?
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status" />
                </div> :
                state.customer != null ?
                    children
                    : <SignInPage></SignInPage> /*no token*/}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };


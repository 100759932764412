import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { CustomerModel, PosApi, RewardModel } from "../../../apiclient";
import { apiConfig } from "../../../config";
import NotyfContext from "../../../contexts/NotyfContext";
import CustomerDetailsComponent from "./CustomerDetailsComponent";
import RedeemRewardListComponent from "./RedeemRewardListComponent";

declare const posContext: any;

/* LSK related context variables */
declare global {
    interface Window {
        webkit: any;
        pos_getCurrentAccount: (func: any) => void;
        pos_getAvailableDiscounts: (func: any) => void;
        pos_toggleGlobalDiscount: (code: string, func: any) => void;
        pos_setConsumer: (consumer: any) => void;
        pos_close: () => void;
    }
}

interface POSAccount {
    data?: POSAccountData;
}
interface POSAccountData {
    consumer?: Customer;
}
interface Customer {
    consumer?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber1?: string;
}

const CustomersProfilePage = () => {
    const notyf = useContext(NotyfContext);
    const [posAccount, setPOSAccount] = useState<POSAccount>({});
    const [posContextData, setPOSContextData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [customer, setCustomer] = useState<CustomerModel | undefined>();
    let posApi = new PosApi(apiConfig.configuration, apiConfig.baseUrl + "/api");

    // This is the testing redeem callback, for kseries we will set the callback to do the discount.
    // Then for other integrations we just need to set what the redeem callback does after a successful redeem on the api.
    let redeemCallback = (reward: RewardModel) => {
        if (window.pos_toggleGlobalDiscount) {
            window.pos_toggleGlobalDiscount(reward.redeemCode as string, () => { });
            window.pos_close();
        }
        else if(process.env.NODE_ENV !== "production") {
            alert("Please copy paste this code into your POS" + reward.redeemCode);
        }

        return true;
    }

    useEffect(() => {
        const fetchDetails = async () => {
            if (posAccount?.data?.consumer?.email) {
                try {
                    let response = await posApi.apiPosCustomerEmailGet(posAccount?.data?.consumer?.email);
                    setCustomer(response.data);
                    setLoading(false);
                } catch (error) {
                    notyf.open({
                        type: "error",
                        message: "Sorry something went wrong, please try again",
                        duration: 5000,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                    setLoading(false);
                }
            }
        };
        setLoading(true);
        // This part is for kseries but we can make methods to detect the provider and customer email for other integrations
        if (window.pos_getCurrentAccount) {
            window.pos_getCurrentAccount((response: POSAccount) => {
                setPOSAccount(response);
            });

            const intervalId = setInterval(() => {
                if (posContext) {
                    clearInterval(intervalId); // Clearing the interval when posContext is available

                    setPOSContextData(posContext);
                    axios.defaults.headers.common["x-marsello-site"] = posContext.locationId;
                    // in here we would set an api key or header for our api calls to tell us which provider we are using
                    fetchDetails();
                }
            }, 10);

        } else if (process.env.NODE_ENV !== "production") {
            axios.defaults.headers.common["x-marsello-site"] = "965276719906818";
            setPOSAccount({ data: { consumer: { email: "rukshan+lsk_user1@marsello.com" } } })
            setPOSContextData({ businessId: "965276719906818" });

            fetchDetails();
        }
    }, [posAccount?.data?.consumer?.email]);

    return (
        <React.Fragment>
            <Helmet title="Customer Profile" />
            <div className="pos-content" style={{ margin: "20px" }}>
                {/* <div><pre>{JSON.stringify(posContext, null, 2)}</pre></div> */}
                <Container fluid className="p-0">
                    {loading ?
                        <div>
                            <h1 id="tabelLabel">Loyalty Profile</h1>
                            <Container fluid className="p-0">
                                <Row>
                                    <Col md="5" xl="4">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem", paddingBottom: "2rem" }}>
                                            <Spinner animation="border" role="status" />
                                        </div>
                                    </Col>
                                    <Col md="7" xl="8">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem", paddingBottom: "2rem" }}>
                                            <Spinner animation="border" role="status" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        :
                        (customer != null) ?
                            <div>
                                <h1 id="tabelLabel">Loyalty Profile</h1>
                                <Container fluid className="p-0">
                                    <Row>
                                        <Col md="5" xl="4">
                                            <CustomerDetailsComponent customer={customer} />
                                        </Col>
                                        <Col md="7" xl="8">
                                            <RedeemRewardListComponent customer={customer} redeemCallback={redeemCallback} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            :
                            <Alert variant="warning">
                                <div className="alert-message">
                                    <strong>No customer attached!</strong> Please select a customer to view loyalty profile from Marsello.
                                </div>
                            </Alert>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomersProfilePage;

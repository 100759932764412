import React, {Component} from 'react'
import $ from 'jquery'

export default class JQueryMenuCode extends Component {
    jQuerycode = () => {
        
            $('.nav-icon').click(function () {
                $('body').toggleClass('open');
                $('.nav-icon').toggleClass('open');
            });
            //    $('nav > ul > li > a').click(function () {
            //        $('body').removeClass('open');
            //        $('.nav-icon').removeClass('open');
            //    });
        
            $('ul.topmenu li.menu-item-has-children').append('<span class="click_menu"></span>');
            $('.nav-icon').click(function () {
                $(this).toggleClass('change');
                $('nav').slideToggle();
            });
           
            $('li.menu-item-has-children .click_menu').click(function () {
                $(this).toggleClass('open2');
                $(this).parent('li').siblings('li').find('.click_menu').removeClass('open2');
                $(this).parent('li').find('.sub-menu').slideToggle();
                $(this).parent('li').siblings('li').find('.sub-menu').slideUp();
            });

    }

    componentDidMount(){
      this.jQuerycode()
    }
    render() {
      return (
        <></>
      )
    }
  }
  
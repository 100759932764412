import React, {
    useEffect,
    useState
} from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import {
    AccountApi,
    CustomerApi,
    SignInPageViewModel
} from '../../apiclient';
import { PowerbyFooter } from '../../components/PowerbyFooter';
import { apiConfig } from '../../config';
import styles from './LoginComponent.module.css';

const VerifyPage = () => {
    let customerApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl)
    let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl)
    const [data, setData] = useState({ message: "", verified: true});
    const [account, setAccount] = useState({} as SignInPageViewModel)
    let { token } = useParams();
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = () => {
            accountApi.accountSignInGet().then(res => {
                setAccount(res.data);
            }).then(() => {
                if (token) {
                    customerApi
                        .customerVerifyTokenGet(token)
                        .then((res) => {
                            setData({ message: "Your email has been verified", verified: true });
                        }).catch((err) => {
                            setData({ message: "Sorry your verification failed", verified: false });
                        })

                } else {
                    setData({ message: "We couldn't find your verification token", verified: false });
                }
            })
        }
    fetchData();
    }, [])

  return (
      <React.Fragment>
          {(!account) ?
              <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" role="status" />
              </div> :
              <div>
            <div className="banner_inner">
                  <img src={account?.accountBranding?.signupBannerUrl ?? ""} alt="" />
              </div><div className={`verify_form form_box ${styles.signInFormContainer}`}>
                      <div className="container">
                          <div className="row">
                              <div className="col-md-12">
                                  <div className={`${styles.textCenter}`}>
                                      <div className="heading">
                                          <h2>Verifying</h2> :
                                      </div>
                                      <div className="mb-3 form-floating">
                                          <span>{(data.message)}</span>
                                      </div>
                                  </div>
                                  <br />
                                  <Button className={'btn btn-orange btn-full-width'} onClick={() => navigate("/")} hidden={(!data.message)}>{(data.verified) ? "Continue" : "Try again"}</Button>
                                  <br />
                                  <PowerbyFooter />
                              </div>
                          </div>
                      </div>
                  </div>
          </div>}
        </React.Fragment>
      )
}

export default VerifyPage

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import {
    ReceiptApi,
    ReceiptViewModel,
    SignInPageViewModel
} from "../../apiclient";
import { PowerbyFooter } from "../../components/PowerbyFooter";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import NotyfContext from "../../contexts/NotyfContext";
import defaultBanner from "../../images/background.png";
import styles from "../Account/LoginComponent.module.css";

const ReceiptPage = () => {
    let receiptApi = new ReceiptApi(apiConfig.configuration, apiConfig.baseUrl);
    const [receiptModel, setReceiptModel] = useState({} as ReceiptViewModel);
    const [model, setModel] = useState({} as SignInPageViewModel);
    const [isLoading, setLoading] = useState(true as boolean);
    const notyf = useContext(NotyfContext);
    const authContext = useContext(AuthContext);
    let { id } = useParams();

    const [searchParams] = useSearchParams();
    let utm_source = searchParams.get("utm_source");
    let utm_medium = searchParams.get("utm_medium");
    let utm_campaign = searchParams.get("utm_campaign");
    let utm_id = searchParams.get("utm_id");

    useEffect(() => {
        const fetchData = () => {
            receiptApi
                .receiptReceiptGet(id)
                .then((response) => {
                    setReceiptModel(response.data);
                    setLoading(false);
                })
                .catch((error: any) => {
                    notyf.open({
                        type: "error",
                        message: "Sorry something went wrong, please try again",
                        duration: 5000,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                    setLoading(false);
                });
            if(authContext.account) setModel(authContext.account);

        };

        fetchData();
        saveUtmsToStorage();
    }, []);

    const saveUtmsToStorage = () => {
        console.log("utm_source", utm_source);
        if (utm_source) {
            localStorage.setItem("utm_source", utm_source);
        }

        if (utm_medium) {
            localStorage.setItem("utm_medium", utm_medium);
        }

        if (utm_campaign) {
            localStorage.setItem("utm_campaign", utm_campaign);
        }

        if (utm_id) {
            localStorage.setItem("utm_id", utm_id);
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <>
            {isLoading ? (
                <></>
            ) : (
                <React.Fragment>
                    <div className="banner_inner">
                        {model.accountBranding?.signupBannerUrl ? (
                                <img src={model.accountBranding?.signupBannerUrl} alt="" />
                        ) : (
                            <img src={defaultBanner} alt="" />
                        )}
                    </div>
                    <div
                        className={`sign_in_form form_box ${styles.signInFormContainer}`}
                    >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="heading">
                                        <h4>Receipt #{receiptModel.title}</h4>
                                        <div className="text-muted">
                                            {receiptModel.siteName}
                                        </div>
                                        <div className="text-muted">
                                            {moment
                                                .utc(receiptModel.orderDateAt)
                                                .local()
                                                .format(
                                                    "MMMM Do YYYY | h:mm:ss A z"
                                                )}
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 col-6 mx-auto mt-20">
                                        <a
                                            href={`/Account/ReceiptPdf?orderId=${receiptModel.sourceOrderId}`}
                                            className="btn btn-primary"
                                            type="button"
                                        >
                                            View receipt
                                        </a>
                                    </div>
                                    <div>
                                        {!authContext.customer ? (
                                            <div>
                                                <div className="d-grid gap-2 col-6 mx-auto mt-20">
                                                    <a
                                                        href="/"
                                                        className="btn btn-secondary"
                                                        type="button"
                                                    >
                                                        Back to home
                                                    </a>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={`mt-20 ${styles.textCenter} ${styles.headerText} ${styles.lineHeight24}`}
                                                >
                                                    <span>
                                                                {model.accountBranding?.headerText}
                                                    </span>
                                                </div>
                                                <div className="d-grid gap-2 col-6 mx-auto mt-20">
                                                    <a
                                                        href="/"
                                                        className="btn btn-secondary"
                                                        type="button"
                                                    >
                                                        Sign-up
                                                    </a>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <PowerbyFooter />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default ReceiptPage;

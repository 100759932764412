import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountBranding, SignInPageViewModel } from "../apiclient";
import { AuthContext } from "../contexts/AccountContext";
import MyCardModalComponent from "../pages/Home/MyCardModalComponent";
import JQueryMenuCode from "./JQueryMenuCode";

const Header = (props:any) => {

    let model = props.parentModel as SignInPageViewModel;
    const [branding, setBranding] = useState({} as AccountBranding);
    const navigate = useNavigate();
    const [showMyCardPopup, setShowMyCardPopup] = useState(false as boolean);
    const authContext = useContext(AuthContext)

    useEffect(() => {
        const fetchData = () => {
            if (authContext.account?.accountBranding) setBranding(authContext.account.accountBranding)
        };

        fetchData();
    });

    const logout = () =>{
        authContext.signOut()
    }

    return (
        <React.Fragment>
            <header className="header">
                    <div className="nav_bg clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <div className="logo">
                                        <a onClick={()=>navigate("/")}>
                                        {(branding && branding?.navLogoUrl)? (<img
                                            src={branding.navLogoUrl}
                                                alt={model.companyName}
                                            />):(
                                                <h5 className="nav-company-name">{model.companyName}</h5>
                                            )}
                                        </a>
                                    </div>
                                    <div className="main-nav">
                                        <nav className="clearfix">
                                            <div className="inner_nav d-flex align-items-center">
                                                <ul className="clearfix topmenu">
                                                    <li>
                                                        <a  onClick={()=>navigate("/")}>
                                                            Home
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a  onClick={()=>navigate("/earn")}>
                                                            Earn
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a  onClick={()=>navigate("/redeem")}>
                                                            Redeem
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a  onClick={()=>navigate("/notifications")}>
                                                            Notification settings
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a  onClick={()=>navigate("/receipts")}>
                                                            Receipts
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={logout}>
                                                            Log out
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                        <a className="btn btn-blue" onClick={()=>setShowMyCardPopup(true)}>
                                            Card
                                        </a>
                                    </div>
                                    <div className="nav-icon ml-auto">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <MyCardModalComponent show={showMyCardPopup} onHide={()=>setShowMyCardPopup(false)} />
                <JQueryMenuCode />
        </React.Fragment>
    );
};

export default Header;

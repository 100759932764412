import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { HomePageRewardListItem } from "../../apiclient";
import NotyfContext from "../../contexts/NotyfContext";
import closeIcon from "../../images/close-icon.svg";


const RedeemModalComponent = (props: any) => {

    let model = props.reward as HomePageRewardListItem;
    let membershipId = props.membershipId as string;
    const navigate = useNavigate();

    const handleClose = () => {
        props.onHide();
    }

    const notyf = useContext(NotyfContext);



    return (
        <React.Fragment>
            <Modal
                className="reward_popup"
                show={props.show}
                onHide={props.onHide}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <img src={closeIcon} alt="" />
                            </button>
                            <div className="reward_content">
                                <div className="reward_code">
                                    <div className="qr_code">
                                        {membershipId && <QRCode value={membershipId} size={250} />}
                                    </div>
                                    <h3>{model.redeemCode}</h3>
                                    <p>
                                        Present this code to staff to find your profile and redeem reward
                                    </p>
                                </div>
                                <div className="reward_text">
                                    {
                                        (model.pointsRequired ?? 0) > 0 &&
                                        <h2>
                                            {model.pointsRequired} <sub>{model.pointsLabel}</sub>
                                        </h2>
                                    }
                                    <h4>{model.rewardTitle}</h4>
                                    <p>
                                        {model.terms}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default RedeemModalComponent;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { EarnActivityModel, HomeApi, PortalCustomerModel } from "../../apiclient";
import Header from "../../components/Header";
//import { DashboardApi, RecentActivityComponentViewModel } from "../../apiclient";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import NotyfContext from "../../contexts/NotyfContext";
import tadaIcon from "../../images/img.png";
import MyCardModalComponent from "../Home/MyCardModalComponent";
import CompleteProfileModalComponent from "./CompleteProfileModalComponent";
import ReferralModalComponent from "./ReferralModalComponent";



const EarnPage = (props: any) => {
    let homepageApi = new HomeApi(
        apiConfig.configuration,
        apiConfig.baseUrl
    );

    const authContext = useContext(AuthContext);

    const [showReferralPopup, setShowReferralPopup] = useState(false as boolean);
    const [showMyCardPopup, setShowMyCardPopup] = useState(false as boolean);
    const [showCompleteProfilePopup, setShowCompleteProfilePopup] = useState(false as boolean);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const [completingOption, setCompletingOption] = useState("" as string);
    const navigate = useNavigate();
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer) {
                setModel(authContext.customer);
            }
        };

        fetchData();
    }, []);



    if (!model) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    const completeActivity = (option: EarnActivityModel) => {
        setCompletingOption(option?.id ?? '');

        homepageApi.homeRecordActivityPost((option.id as string | undefined))
            .then(response => {
                if (response.data?.destinationUrl) {
                    var redirectUrl = response.data.destinationUrl;
                    if (redirectUrl) {
                        window.location.href = redirectUrl
                    }
                    refreshModel();
                } else {
                    notyf.open({
                        type: "error",
                        message: "An error occurred. Please try again",
                        duration: 5000,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                }

                setCompletingOption('');

            })

    }

    const refreshModel = () => {
        authContext.refreshCustomer()
    }

    const completeProfileSaved = () => {
        // hide modal
        setShowCompleteProfilePopup(false);
        authContext.refreshCustomer()
    }

    const RenderEarnOption = (option: EarnActivityModel) => {
        if (option.id?.includes("tier")) {
            return (
                <div className="points_box">
                    <em>{option.title} Tier</em>
                    <div>Earn {option.description} <div style={{ fontWeight: "normal", paddingTop: "10px" }}>Unlock at {option.threshold?.toLocaleString()} {model.pointsLabel}</div></div>
                </div>
            );
        } else if (option.lastRewarded) {
            return (
                <div className="points_box points_earned">
                    <em>+{option.points} {model.pointsLabel}</em>
                    <p>{option.title}</p>
                    <h6>{option.points} {model.pointsLabel} earned on {moment(option.lastRewarded).format('DD MMM, yyyy')} <img src={tadaIcon} alt="" /></h6>
                </div>
            );
        }
        else if (option.id == "profile-completed") {
            return (
                <div className="points_box">
                    <em>+{option.points} {model.pointsLabel}</em>
                    <p>{option.title}</p>
                    <a onClick={() => setShowCompleteProfilePopup(true)} className="btn btn-orange" data-bs-toggle="modal" data-bs-target="#referral_popup">Earn +{option.points} {model.pointsLabel}</a>
                </div>
            );
        }
        else if (option.id == "birthday") {
            return (
                <div className="points_box">
                    <em>+{option.points} {model.pointsLabel}</em>
                    <p>{option.title}
                        {model.birthdate && <div style={{ fontWeight: "normal", paddingTop: "10px" }}>{option.description}</div>}
                    </p>
                    {!model.birthdate && <a onClick={() => setShowCompleteProfilePopup(true)} className="btn btn-orange" data-bs-toggle="modal" data-bs-target="#referral_popup">Earn +{option.points} {model.pointsLabel}</a>}
                </div>
            );
        } else if (option.id == "referral" && model.referralLink) {
            return (
                <div className="points_box">
                    <em>+{option.points} {model.pointsLabel}</em>
                    <p>{option.title}</p>
                    <a onClick={() => setShowReferralPopup(true)} className="btn btn-orange" data-bs-toggle="modal" data-bs-target="#referral_popup">Earn +{option.points} {model.pointsLabel}</a>
                </div>
            );
        }
        else {
            return (
                <div className="points_box">
                    <em>+{option.points} {model.pointsLabel}</em>
                    <p>{option.title}</p>
                    {completingOption == option.id ? (
                        <a className="btn btn-orange">Loading..</a>
                    ) : (
                        <a onClick={() => completeActivity(option)} className="btn btn-orange" data-bs-toggle="modal" data-bs-target="#referral_popup">Earn +{option.points} {model.pointsLabel}</a>
                    )}
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            <Header parentModel={model} />
            <main>
                <div className="earn_top_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Earn Points</h1>
                                <div className="my_card_box">
                                    <em>+{model.earningPointsMessage}</em>
                                    <p>Scan your card at point of purchase to earn {model.earningPointsMessage} spent</p>
                                    <a onClick={() => setShowMyCardPopup(true)} className="btn btn-orange" data-bs-toggle="modal" data-bs-target="#my_card">Show my card</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="earn_points_sec">
                    <div className="container">
                        <div className="row">
                            {model.earnOptions && model.earnOptions.map((option: EarnActivityModel, i: number) => (
                                <div className="col-6 points_main_box" key={"earn" + i}>
                                    {RenderEarnOption(option)}
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </main>
            <footer className="footer mt-auto">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul>
                                <li><a onClick={() => navigate("/")} className="btn btn-grey">Home</a></li>
                                <li className="active"><a onClick={() => navigate("/earn")} className="btn btn-grey">Earn</a></li>
                                <li><a onClick={() => navigate("/redeem")} className="btn btn-grey">Redeem</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <ReferralModalComponent show={showReferralPopup} onHide={() => setShowReferralPopup(false)} />
            <MyCardModalComponent show={showMyCardPopup} onHide={() => setShowMyCardPopup(false)} />
            <CompleteProfileModalComponent show={showCompleteProfilePopup} onHide={() => setShowCompleteProfilePopup(false)} onSave={completeProfileSaved} />
        </React.Fragment>
    );
};

export default EarnPage;

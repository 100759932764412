import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { Button } from 'react-bootstrap';
import {
  CustomerApi,
  SignInPageViewModel, SignUpPostRequestModel
} from '../../apiclient';
import { PowerbyFooter } from '../../components/PowerbyFooter';
import { apiConfig } from '../../config';
import { AuthContext } from '../../contexts/AccountContext';
import NotyfContext from '../../contexts/NotyfContext';
import appleIcon from '../../images/apple-logo-white.png';
import defaultBanner from "../../images/background.png";
import facebookIcon from '../../images/f_logo_RGB-White_58.png';
import googleIcon from '../../images/google-icon.png';
import styles from './LoginComponent.module.css';

const SignInPage = () => {
  let customerApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl)
    const [model, setModel] = useState({} as SignInPageViewModel)
    const [data, setData] = useState({ email: "", verificationSent: false });
    const [error, setError] = useState({ unverifiedEmail: "" });
  const notyf = useContext(NotyfContext)
  const authContext = useContext(AuthContext)

  let loginWindow: Window | null = null;

  const openLoginPopup = (subUrl: string) => {
    let url = model.loginUrl + subUrl;
    loginWindow = window.open(url, "loginWindow", "width=450,height=650directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no")
    window.onfocus = function () {
      if (loginWindow) window.location.reload()
    }
    return true;
  };


  useEffect(() => {
      const fetchData = () => {
          if (authContext.account) {
              updateManifest(authContext.account)
              setModel(authContext.account)
          }
    }

    fetchData();
  }, [])

  const submitForm = () => {
      const customer_id = localStorage.getItem("customer_id") ?? "";
    // Call api to create new customer
    customerApi
      .customerPost('1.0', { acceptMarketing: true, customerId: customer_id, unverifiedEmail: data.email } as SignUpPostRequestModel)
        .then((res) => {
            if (res.status === 202) {
                setData({...data, verificationSent: true})
            } else {
                notyf.open({
                    type: 'success',
                    message: 'Sign up successfully',
                    duration: 1300,
                    ripple: true,
                    dismissible: true,
                    position: {
                        x: 'right',
                        y: 'top',
                    },
                })
                setTimeout(function () {
                    authContext.refreshCustomer()
                }, 1400)
            }
      }).catch((err) => {
        setError(err?.response?.data);
        notyf.open({
          type: 'error',
          message: 'Sign up failed',
          duration: 1500,
          ripple: true,
          dismissible: true,
          position: {
            x: 'right',
            y: 'top',
          },
        })
      })
  }

    const updateManifest = (resData: SignInPageViewModel) => {

    var myDynamicManifest = {
      "name": "Marsello Customer Instore Portal",
        "short_name": resData?.accountBranding?.appShortName ?? `${resData?.companyName} loyalty`,
        "start_url": window.location.href,
      "background_color": "#ffffff",
      "theme_color": "#000000",
      "icons": [
          {
              "src": resData?.accountBranding?.appIconUrl ?? "https://s3.amazonaws.com/collectapps.images.accounts/InstorePortalDefaultIcon.png",
          "sizes": "64x64 32x32 24x24 16x16",
          "type": "image/png"
        }
      ]
    }
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], {type: 'application/json'});
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#portalManifest')?.setAttribute('href', manifestURL);
    }

    const handleChange = (event: any) => {
        setData({ ...data, [event.target.name]: event.target.value });
      };



  return (
        <React.Fragment>
                      <div className="banner_inner">
                          {model?.accountBranding?.signupBannerUrl ? (
                              <img src={model.accountBranding.signupBannerUrl} alt="" />
            ) : (
              <img src={defaultBanner} alt="" />
            )}
          </div>
          <div className={`sign_in_form form_box ${styles.signInFormContainer}`} >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                          <div className="heading">
                              {(data.verificationSent) ?
                                  <h2> Check your Email</h2> :
                                  <h2>
                                      Create account or login
                                  </h2>
                              }
                  </div>
                  <div>
                    <div className={`${styles.textCenter} ${styles.headerText} ${styles.lineHeight24}`} hidden={data.verificationSent}>
                      <span>{model?.accountBranding?.headerText}</span>
                    </div>
                              {(!authContext.authenticated && !data.verificationSent) ?
                                  <div className={styles.loginButtonContainer}>
                                      <Button variant='light' className={styles.googleLoginButton}
                                          onClick={function () { openLoginPopup("/Google") }}>
                                          <img src={googleIcon} className={styles.loginButtonGoogleLogo} />
                                          Continue with Google</Button>
                                      <Button variant='light' className={styles.facebookLoginButton}
                                          onClick={function () { openLoginPopup("/Facebook") }}>
                                          <img src={facebookIcon} className={styles.loginButtonFacebookLogo} />
                                          Continue with Facebook
                                      </Button>
                                      <Button variant='dark' className={styles.appleLoginButton}
                                          onClick={function () { openLoginPopup("/Apple") }}>
                                          <img src={appleIcon} className={styles.loginButtonAppleLogo} />
                                          Sign in with Apple
                                      </Button>
                                  </div>
                                  :
                                  <div className={`${styles.textCenter}`} hidden={(data.verificationSent)}>
                                      <div className="mb-3 form-floating">
                                          <input
                                              placeholder="Email address"
                                              type="email"
                                              id="signupEmail"
                                              className="form-control"
                                              name="email"
                                              hidden={(!authContext?.account?.requireEmail)}
                                              required={(authContext?.account?.requireEmail)}
                                              onChange={handleChange}
                                          />
                                          <label htmlFor="signupEmail">Email address</label>
                                          <span style={{ color: "#d9534f" }}>{error.unverifiedEmail}</span>
                                      </div>
                                      <br />
                                      <Button className={'btn btn-orange btn-full-width'} onClick={submitForm}>Create account</Button>
                                      <br />
                                      <br />
                                      <a href="/account/logout">Cancel</a>
                                  </div>}
                              {(data.verificationSent) ?
                                  <div className={`${styles.textCenter}`}>
                                      <div className="mb-3 form-floating">
                                          <span>Please check your email and click the link to start earning points.</span>
                                      </div>
                                  </div> : <></>}

                    <div className={`${styles.acceptMarketingTermsText} ${styles.textCenter} ${styles.lineHeight24}`}>
                      By signing up you agree to our <a href='https://www.marsello.com/terms' target="_blank" rel="noreferrer">terms</a> and accept email and SMS marketing
                    </div>
                    <br />
                    <PowerbyFooter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
}

export default SignInPage

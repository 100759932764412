import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HomePageRewardListItem, PortalCustomerModel } from "../../apiclient/models";
import Header from "../../components/Header";
import { AuthContext } from "../../contexts/AccountContext";
import arrowIcon from "../../images/arrow.png";
import RedeemModalComponent from "./RedeemModalComponent";

const RedeemPage = () => {
    const [showRedeemPopup, setShowRedeemPopup] = useState(false as boolean);
    const [rewardToRedeem, setRewardToRedeem] = useState({} as HomePageRewardListItem);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer) {
                setModel(authContext.customer);

                // Check if a rewardId is present, and use it if so
                var rewardId = searchParams.get("rewardId");
                var reward = authContext.customer.availableToRedeemRewardList?.find(item => item.rewardId == rewardId);
                if (reward != null) {
                    setRewardToRedeem(reward);
                    setShowRedeemPopup(true);
                }
            }
        };
        fetchData();
    }, []);

    if (!model) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    const selectRewardToRedeem = (selectedReward: HomePageRewardListItem) => {
        setRewardToRedeem(selectedReward);
        setShowRedeemPopup(true);
    }

    return (
        <React.Fragment>
            <Header parentModel={model} />
            <main>
                <div className="redeem_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading">
                                    <h1>You have {model.pointsToSpend?.toLocaleString()} {model.pointsLabel} to spend</h1>
                                </div>
                                {model.availableToRedeemRewardList && model.availableToRedeemRewardList.length > 0 &&
                                    <div className="available_redeem">
                                        <h3>Available to redeem</h3>
                                        <ul>
                                            {model.availableToRedeemRewardList.map((reward, i) => (

                                                <li onClick={() => selectRewardToRedeem(reward)} key={'reward' + i} className="d-flex align-items-center">
                                                    <div className="redeem_icon">
                                                        {reward.pointsRequired == 0 ? "Gift" : reward.pointsRequired}
                                                    </div>
                                                    <div className="redeem_text">
                                                        <h4>{reward.rewardTitle}</h4>
                                                        <h5>{reward.endDate &&
                                                            <>Expires:  {moment(reward.endDate).format("DD MMM, yyyy")}</>
                                                        }</h5>
                                                    </div>
                                                    <a className="arrow-icon"><img src={arrowIcon} alt="" /></a>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }

                                {model.lockedRewardList && model.lockedRewardList.length > 0 &&
                                    <div className="unlocked_redeem">
                                        <h3>To be unlocked</h3>
                                        <ul>
                                            {model.lockedRewardList.map((reward, i) => (
                                                <li key={'locked-reward' + i} className="d-flex align-items-center">
                                                    <div className="redeem_icon">
                                                        {reward.pointsRequired}
                                                    </div>
                                                    <div className="redeem_text">
                                                        <h4>{reward.rewardTitle}</h4>
                                                        <p>{reward.pointsNeededToUnlockReward} points needed to unlock reward</p>
                                                    </div>
                                                    <div className="progressbar" style={{ width: `${reward.percentProgressUntilUnlockReward ?? 0}%` }}></div>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="footer mt-auto">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul>
                                <li><a onClick={() => navigate("/")} className="btn btn-grey">Home</a></li>
                                <li><a onClick={() => navigate("/earn")} className="btn btn-grey">Earn</a></li>
                                <li className="active"><a onClick={() => navigate("/redeem")} className="btn btn-grey">Redeem</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            {rewardToRedeem && <RedeemModalComponent reward={rewardToRedeem} membershipId={model.membershipId} show={showRedeemPopup} onHide={() => setShowRedeemPopup(false)} />}
        </React.Fragment>
    );
};

export default RedeemPage;

import bannerImage from '../images/Powered by Marsello.png'

export const PowerbyFooter = () => {
  return (
      <a href="https://hubs.li/H0M12QQ0" target={"_blank"}>
      <div style={{display:'flex', justifyContent:'center'}}>
        <img src={bannerImage} style={{marginTop:'35px'}}/>
        </div>
      </a>
  )
}

// import React, { Component } from 'react';
// import { Route } from 'react-router';
// import { Layout } from './components/Layout';
// import { Home } from './components/Home';
// import { FetchData } from './components/FetchData';
// import { Counter } from './components/Counter';

// import './custom.css'

// export default class App extends Component {
//   static displayName = App.name;

//   render () {
//     return (
//       <Layout>
//         <Route exact path='/' component={Home} />
//         <Route path='/counter' component={Counter} />
//         <Route path='/fetch-data' component={FetchData} />
//       </Layout>
//     );
//   }
// }

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";
import "./custom.css";
import routes from "./routes";


const App = () => {
    const content = useRoutes(routes);
    return (
        <HelmetProvider>
            <Helmet
                titleTemplate="%s | Marsello"
                defaultTitle="Customer Rewards Portal"
            />
                {content}
        </HelmetProvider>
    );
};

export default App;
//export default aiTrackedComponent(App);

import { Configuration } from "./apiclient";


export const loginUrl = process.env.NODE_ENV === "production" ? "https://login.marsello.app/account/login" : "https://localhost:44464/account/login"


export const apiConfig = {
    baseUrl: window.location.protocol,
    configuration: new Configuration(),
};

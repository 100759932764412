import React from "react";
import { Outlet } from "react-router-dom";
import { AuthProvider } from "../contexts/AccountContext";

const AppLayout = ({ children }) => {
    return (
        <React.Fragment>
            <div className="wrapper">
                <AuthProvider>
                {children}
                    <Outlet />
                    </AuthProvider>
            </div>
        </React.Fragment>
    );
};

export default AppLayout;

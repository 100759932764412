import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { PortalCustomerModel } from "../../apiclient";
import NotyfContext from "../../contexts/NotyfContext";

import {
    MobileView
} from "react-device-detect";
import { AuthContext } from "../../contexts/AccountContext";
import androidInstructions from "../../images/AndroidInstructions.png";
import iosInstructions from "../../images/AppleInstructions.png";
import closeIcon from "../../images/close-icon.svg";

const MyCardModalComponent = (props: any) => {
    const [isLoading, setLoading] = useState(true as boolean);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const [model, setModel] = useState({} as PortalCustomerModel);
    const notyf = useContext(NotyfContext);
    const authContext = useContext(AuthContext);

    const [showModalSaveToHomescreen, setShowModalSaveToHomescreen] = useState(
        false as boolean
    );

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer) {
                setModel(authContext.customer);
            }
        };

        fetchData();
    }, []);

    const isIOS = () => {
        return (
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    };

    const showInstructions = () => {
        props.onHide();
        setShowModalSaveToHomescreen(true);
    }

    return (
        <React.Fragment>
            <Modal
                className="my_card_popup"
                show={props.show}
                onHide={props.onHide}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => props.onHide()}
                            >
                                <img src={closeIcon} alt="" />
                            </button>
                            <div className="my_card_content">
                                <div className="my_card_text">
                                    <h1>My Card</h1>
                                    <p>
                                        Scan this QR code when making a purchase
                                        to earn more points!
                                    </p>
                                    {/* <a href="#"><img src={applewalletlogo} alt="" /></a> <em>or</em> <a href="#" className="bookmark">bookmark page</a> */}

                                    <MobileView>
                                        <a
                                            onClick={showInstructions}
                                            className="bookmark"
                                        >
                                            Save to phone home screen
                                        </a>
                                    </MobileView>
                                </div>
                                <div className="my_card_code">
                                    <div className="qr_code">
                                        {model.membershipId && (
                                            <QRCode
                                                value={model.membershipId}
                                                size={250}
                                            />
                                        )}
                                    </div>
                                    <a href="#" className="phone">
                                        {model.customerMobile}
                                    </a>
                                    <h5>{model.customerName}</h5>
                                    <a href="#" className="email">
                                        {model.customerEmail}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {showModalSaveToHomescreen && (
                <Modal
                    className="referral_popup complete_profile_popup"
                    show={showModalSaveToHomescreen}
                    onHide={() => setShowModalSaveToHomescreen(false)}
                >
                    <div className="account_form form_box">
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShowModalSaveToHomescreen(false)}
                        >
                            <img src={closeIcon} alt="" />
                        </button>

                        <div className="container referral_content">
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading">
                                        <h6 className="complete-profile-header">
                                            How to save this card to your phone
                                            home screen
                                        </h6>
                                    </div>
                                    <h4></h4>
                                    {isIOS() ? (
                                        <img src={iosInstructions} />
                                    ) : (
                                        <img src={androidInstructions} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};

export default MyCardModalComponent;

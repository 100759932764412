import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";
import {
    CustomerModel,
    PosApi,
    RewardModel
} from "../../../apiclient";
import { apiConfig } from "../../../config";
import NotyfContext from "../../../contexts/NotyfContext";
interface RedeemRewardListProps {
    customer: CustomerModel;
    redeemCallback: (reward: RewardModel) => boolean;
}

const RedeemRewardListComponent = ({ customer, redeemCallback }: RedeemRewardListProps) => {
    let posApi = new PosApi(apiConfig.configuration, apiConfig.baseUrl + "/api");
    const [isLoading, setLoading] = useState<boolean>(true);
    const rewards = customer.rewards ?? [];
    const notyf = useContext(NotyfContext);

    const RedeemReward = async (reward: RewardModel) => {
        if (customer?.id && reward?.id) {
            setLoading(true);
            posApi.apiPosCustomerCustomerIdRewardRewardIdPut(customer.id, reward.id)
                .then(response => redeemCallback(reward))
                .catch(error => {
                    notyf.open({
                        type: "error",
                        message: "Sorry something went wrong, please try again",
                        duration: 5000,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                }).finally(() => setLoading(false));
        }
    }

    const renderReward = (reward: RewardModel, index: number) => {
        return (
            <React.Fragment key={index}>
                <div className="row" >
                    <div className="col-3 text-center">
                        <div>
                            <h3 className="h2 mb-0 mt-1">{reward.points}</h3>
                            <span className="font-1">Points Cost</span>
                        </div>
                    </div>
                    <div className="col p-t-5" style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "inline-block" }}>
                            <div className="text-subhead m-top-0 h5 p-t-10">{reward.name}</div>
                        </div>
                    </div>
                    <div className="col text-center p-top-20" style={{ display: "flex", justifyContent: "right" }}>
                        <Button disabled={(customer.balance ?? 0) <= (reward.points ?? 0)} className="float-end" onClick={() => RedeemReward(reward)}>Redeem</Button>
                    </div>
                </div>
                <hr />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Rewards</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            {rewards && rewards.map((reward, index) =>
                                renderReward(reward, index)
                            )}
                        </div>
                    </Card.Body>

                </Card>
            }
        </React.Fragment>
    );
};

export default RedeemRewardListComponent;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
    CompleteProfilePostPayload,
    HomeApi,
    PortalCustomerModel,
    TranslationData
} from "../../apiclient";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import NotyfContext from "../../contexts/NotyfContext";
import closeIcon from "../../images/close-icon.svg";

let homeApi = new HomeApi(apiConfig.configuration, apiConfig.baseUrl);

const CompleteProfileModalComponent = (props: any) => {
    //modal + form
    const notyf = useContext(NotyfContext);
    const authContext = useContext(AuthContext)
    const [inputs, setInputs] = useState({} as CompleteProfilePostPayload);
    const [validated, setValidated] = useState(false);
    const [saving, setSaving] = useState(false);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const [helperData, setHelperData] = useState({} as TranslationData);

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer != null) {
                var parentModel = authContext.customer
                setModel(parentModel);

                setInputs({
                    birthdate: parentModel.birthdate,
                    phone: parentModel.customerMobile,
                    emailAddress: parentModel.customerEmail,
                    firstname: parentModel.customerFirstname,
                    lastname: parentModel.customerLastname,
                    gender: parentModel.gender,
                } as CompleteProfilePostPayload);

            }
        };

        const fetchHelperData = () => {
            homeApi.homeGetTranslationDataGet('1').then(res => {
                setHelperData(res.data);
            })
        }

        fetchData();
        fetchHelperData();
    }, []);

    const handleChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;

        const name = event.target.name;
        setInputs((values: CompleteProfilePostPayload) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            setSaving(true);
            homeApi
                .homeCompleteCustomerProfilePost("1", {
                    firstname: inputs.firstname ?? "",
                    lastname: inputs.lastname ?? "",
                    emailAddress: model.customerEmail ?? "",
                    phone: inputs.phone ?? "",
                    gender: inputs.gender ?? "",
                    birthdate: inputs.birthdate ?? "",
                } as CompleteProfilePostPayload)
                .then(() => {
                    notyf.open({
                        type: "success",
                        message: `You earned ${model.completedProfilePoints} ${model.pointsLabel} for completing your profile`,
                        duration: 2500,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                    setValidated(false);
                    setSaving(false);

                    props.onSave();
                })
                .catch(function () {
                    notyf.open({
                        type: "error",
                        message: "Profile details have not been saved",
                        duration: 2500,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });

                    setSaving(false);
                });
        } else {
            setValidated(true);
            setSaving(false);
        }
    };

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                className="referral_popup complete_profile_popup"
            >
                <div className="account_form form_box">
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.onHide()}
                    >
                        <img src={closeIcon} alt="" />
                    </button>

                    <div className="container referral_content">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading">
                                    <h6 className="complete-profile-header">
                                        Complete profile to earn {model.completedProfilePoints} {model.pointsLabel}
                                    </h6>
                                </div>

                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    <Modal.Body>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} xs="6">
                                                <Form.Label>
                                                    First name
                                                </Form.Label>
                                                <Form.Control
                                                    name="firstname"
                                                    type="text"
                                                    defaultValue={
                                                        inputs.firstname ?? ""
                                                    }
                                                    required
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid firstname.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs="6">
                                                <Form.Label>
                                                    Last name
                                                </Form.Label>
                                                <Form.Control
                                                    name="lastname"
                                                    type="text"
                                                    defaultValue={
                                                        inputs.lastname ?? ""
                                                    }
                                                    required
                                                    onChange={handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid lastname.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group
                                                as={Col}
                                                controlId="phone"
                                            >
                                                <Form.Label>Mobile</Form.Label>
                                                <Form.Control
                                                    name="phone"
                                                    type="text"
                                                    onChange={handleChange}
                                                    value={inputs.phone ?? ""}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid mobile number.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group
                                                as={Col}
                                                xs="6"
                                                controlId="birthdate"
                                            >
                                                <Form.Label>
                                                    Birthdate
                                                </Form.Label>
                                                <Form.Control
                                                    name="birthdate"
                                                    type="date"
                                                    onChange={handleChange}
                                                    value={inputs.birthdate ?? ""}
                                                    max={moment()
                                                        .format("YYYY-MM-DD")
                                                        .toString()}
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid
                                                    birthdate.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                xs="6"
                                                controlId="gender"
                                            >
                                                <Form.Label>Gender</Form.Label>
                                                <Form.Select
                                                    name="gender"
                                                    onChange={handleChange}
                                                    value={inputs.gender ?? ""}
                                                >
                                                    <option value="Male">
                                                        {helperData.genderTranslations?.maleText}
                                                    </option>
                                                    <option value="Female">
                                                        {helperData.genderTranslations?.femaleText}
                                                    </option>
                                                    <option value="NotSpecified">
                                                        {helperData.genderTranslations?.notSpecifiedTect}
                                                    </option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    </Modal.Body>

                                    {saving ? (
                                        <button
                                            className="btn btn-orange btn-full-width"
                                            type="submit"
                                            disabled
                                        >
                                            Saving...
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-orange btn-full-width"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    )}

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default CompleteProfileModalComponent;

import React, { useContext, useState } from "react";
import {
    Card
} from "react-bootstrap";
import { CustomerModel } from "../../../apiclient";
import NotyfContext from "../../../contexts/NotyfContext";
import ManualAdjustmentModalComponent from "./ManualAdjustmentModalComponent";

interface CustomerDetailProps {
    customer: CustomerModel;
}

const CustomerDetailsComponent = ({ customer }: CustomerDetailProps) => {
    const [isLoading, setLoading] = useState(true as boolean);
    const [isPointsAdjustModalOpen, setIsPointsAdjustModalOpen] = useState(false);
    const [isEditCustomerDetailsModalOpen, setIsEditCustomerDetailsModalOpen] = useState(false);
    const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
    const [isMarketingPreferencesModalOpen, setIsMarketingPreferencesModalOpen] = useState(false);
    const [isUpdateVipTierModalOpen, setIsUpdateVipTierModalOpen] = useState(false);
    const [model, setModel] = useState(customer);
    const notyf = useContext(NotyfContext);

    const showEditCustomerDetailsModal = () => {
        setIsEditCustomerDetailsModalOpen(true);
    };

    const showAdjustPointsModal = () => {
        setIsPointsAdjustModalOpen(true);
    };

    const showMarketingPreferencesModal = () => {
        setIsMarketingPreferencesModalOpen(true);
    };

    const updatePointsBalance = (newBalance: number) => {
        model.balance = newBalance;
        setModel({
            ...model,
            balance: newBalance,
        });
    };

    const renderDetailsCard = () => {
        return (
            <Card>

                <Card.Body className="text-center">
                    <img
                        src={"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} // add hash to the end for the gravatar to be pulled locally instead of on our end
                        alt={model.firstName + " " + model.lastName}
                        className="img-fluid rounded-circle mb-2"
                        width="128"
                        height="128"
                    />
                    <Card.Title className="mb-0">{model.firstName + " " + model.lastName}</Card.Title>
                    <div className="text-muted mb-2">{model.email}</div>
                    <div className="text-muted mb-2">{model.phone}</div>
                    <h4 className="mb-2">
                        {model.balance &&
                            model.balance.toLocaleString()}{" "}
                        {"points"}
                    </h4>
                    {/* <div>
                        <DropdownButton
                            variant="light"
                            id="dropdown-basic-button"
                            title="Actions"
                        >
                            <Dropdown.Item onClick={showAdjustPointsModal}>
                                Adjust points balance
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={showMarketingPreferencesModal}
                            >
                                Marketing preferences
                            </Dropdown.Item>

                        </DropdownButton>
                    </div> */}
                </Card.Body>

                <hr className="my-0" />

                {/* {model.customTags && model.customTags.length > 0 && (
                    <>
                        <Card.Body>
                            <Card.Title>Tags</Card.Title>

                            {model.customTags &&
                                model.customTags.map((tag) => (
                                    <Badge bg="secondary" className="me-2 my-1">
                                        {tag}
                                    </Badge>
                                ))}
                        </Card.Body>
                        <hr className="my-0" />
                    </>
                )} */}
                <Card.Body>
                    {/* <Card.Title>
                        Details{" "}
                        <a
                            className="float-end"
                            onClick={showEditCustomerDetailsModal}
                        >
                            Edit
                        </a>
                    </Card.Title> */}

                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Tier:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.tierName ?? "Member"}
                                </strong>
                            </div>
                        </li>
                        {model.gender && (
                            <>
                                <li className="mb-1">
                                    <span className="m-b-10 font-size-14 m-l-5">
                                        Gender:
                                    </span>
                                    <div className="float-end">
                                        <strong className=" font-size-15">
                                            {model.gender}
                                        </strong>
                                    </div>
                                </li>
                            </>
                        )}
                        {/* <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Total orders:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.totalOrders &&
                                        model.totalOrders.toLocaleString()}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Total spent:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.totalSpent}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Average order:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.avgSpentPerOrder}
                                </strong>
                            </div>
                        </li> */}
                    </ul>
                </Card.Body>
                {/*<hr className="my-0" />
                 <Card.Body>
                    <Card.Title>
                        Marketing{" "}
                        <a
                            className="float-end"
                            onClick={showMarketingPreferencesModal}
                        >
                            Edit
                        </a>
                    </Card.Title>

                    <ul className="list-unstyled mb-0">
                        <li className="mb-1 clearfix">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Segment:
                            </span>
                            <div className="float-end">
                                <span className="me-2 my-1 badge bg-secondary">
                                    {model.segmentText}
                                </span>
                            </div>
                        </li>
                        <li className="mb-1 clearfix">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Email subscriber:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.acceptMarketing}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1 clearfix">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Sms subscriber:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.acceptSmsMarketing}
                                </strong>
                            </div>
                        </li>
                        {model.formCustomFields &&
                            model.formCustomFields.length > 0 && (
                                <>
                                    <li className="mb-1">
                                        <span className="m-b-10 font-size-14 m-l-5">
                                            Custom form fields:
                                        </span>
                                        <div className="float-end">
                                            <ul>
                                                {model.formCustomFields &&
                                                    model.formCustomFields.map(
                                                        (field) => (
                                                            <li>
                                                                <strong className=" font-size-15">
                                                                    {field.key}:{" "}
                                                                    {
                                                                        field.value
                                                                    }
                                                                </strong>
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    </li>
                                </>
                            )}
                    </ul>
                </Card.Body>
                <hr className="my-0" />
                <Card.Body>
                    <Card.Title>
                        Loyalty{" "}
                        <a
                            className="float-end"
                            onClick={showAdjustPointsModal}
                        >
                            Edit
                        </a>
                    </Card.Title>

                    <ul className="list-unstyled mb-0">
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Loyalty member:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.loyaltyDefinition}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Tier:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.tierName}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Current loyalty balance:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.pointsBalance &&
                                        model.pointsBalance.toLocaleString()}{" "}
                                    {model.pointsLabel ?? "points"}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Total {model.pointsLabel ?? "points"} collected:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {model.totalPointsCollected &&
                                        model.totalPointsCollected.toLocaleString()}{" "}
                                    {model.pointsLabel ?? "points"}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Member since:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">
                                    {moment.utc(model.createdAt).local().format('Do MMM YYYY')}
                                </strong>
                            </div>
                        </li>
                        <li className="mb-1">
                            <span className="m-b-10 font-size-14 m-l-5">
                                Last visited:
                            </span>
                            <div className="float-end">
                                <strong className=" font-size-15">

                                    {model.lastVisited !== null ?
                                        moment.utc(model.lastVisited).local().format('Do MMM YYYY')
                                        : "Never"}
                                </strong>
                            </div>
                        </li>
                    </ul>
                </Card.Body> */}
            </Card>
        )
    }

    return (
        <React.Fragment>
            {/* <div><pre>{JSON.stringify(props?.posContextData, null, 2)}</pre></div> */}
            {/* <div><pre>{JSON.stringify(props?.posAccount, null, 2)}</pre></div> */}
            {
                renderDetailsCard()
            }
            <ManualAdjustmentModalComponent
                isOpen={isPointsAdjustModalOpen}
                updatePointsBalance={updatePointsBalance}
                onHide={() => setIsPointsAdjustModalOpen(false)}
                pointsBalance={model.balance}
                firstname={model.firstName}
            />
        </React.Fragment>
    );
};

export default CustomerDetailsComponent;

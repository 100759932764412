import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const InstorePage = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  let utm_source = searchParams.get('utm_source')
  let utm_medium = searchParams.get('utm_medium')
  let utm_campaign = searchParams.get('utm_campaign')
  let utm_id = searchParams.get('utm_id')
  let customer_id = searchParams.get('customer_id')

  const saveUtmsToStorage = () => {
    if (utm_source) {
      localStorage.setItem('utm_source', utm_source)
    }

    if (utm_medium) {
      localStorage.setItem('utm_medium', utm_medium)
    }

    if (utm_campaign) {
      localStorage.setItem('utm_campaign', utm_campaign)
    }

    if (utm_id) {
      localStorage.setItem('utm_id', utm_id)
    }

    if(customer_id){
      localStorage.setItem('customer_id', customer_id)
    }
  }

  useEffect(() => {
    saveUtmsToStorage()
    navigate('/')
  }, [])

  return <></>
}

export default InstorePage

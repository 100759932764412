import React, {Component} from 'react'
import $ from 'jquery'
import {CirclePercentProps} from '../models/Home/HomepageModels'


export default class CustomJQueryPercentCode extends Component<CirclePercentProps> {
    constructor(props: CirclePercentProps){
        super(props);
    }
    jQuerycode = () => {
           let backgroundBannerColor = this.props.backgroundBannerColor;
           $(".circle_percent").each(function() {
            var $this = $(this),
                $dataV = $this.data("percent"),
                $dataDeg = $dataV * 3.6,
                $round = $this.find(".round_per"),
                $rotateParam = $dataDeg + 180;
            $round.css("transform", "rotate(" + $rotateParam + "deg)");	
            $this.append(`<div class="circle_inbox" style="background: ${backgroundBannerColor? backgroundBannerColor : '#130a3e'}"><span class="percent_text"></span></div>`);
            $this.prop('Counter', 0).animate({Counter: $dataV},
            {
                duration: 2000, 
                easing: 'swing', 
                step: function (now) {
                    $this.find(".percent_text").text(Math.ceil(now)+"%");
                }
            });
            if($dataV >= 51){
                $round.css("transform", "rotate(" + 360 + "deg)");
                setTimeout(function(){
                    $this.addClass("percent_more");
                },1000);
                setTimeout(function(){
                    $round.css("transform", "rotate(" + $rotateParam + "deg)");
                },1000);
            } 
        });

    }

    componentDidMount(){
      this.jQuerycode()
    }
    render() {
      return (
        <></>
      )
    }
  }
  
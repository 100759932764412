import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountApi, AccountBranding, HomePageRewardListItem, PortalCustomerModel, UtmTrackingPostRequestModel } from "../../apiclient";
import Header from "../../components/Header";
import JQueryCirclePercentCode from "../../components/JQueryCirclePercentCode";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import RedeemModalComponent from "../Redeem/RedeemModalComponent";
import MyCardModalComponent from "./MyCardModalComponent";

const HomePage = () => {
    let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl);
    const authContext = useContext(AuthContext);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const [branding, setBranding] = useState({} as AccountBranding);
    const [showMyCardPopup, setShowMyCardPopup] = useState(false as boolean);
    const [showRedeemPopup, setShowRedeemPopup] = useState(false as boolean);
    const [rewardToRedeem, setRewardToRedeem] = useState(
        {} as HomePageRewardListItem
    );
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = () => {
            if (authContext.account?.accountBranding) setBranding(authContext.account.accountBranding)
            if (authContext.customer) {
                setModel(authContext.customer);
                saveUtmsToCustomer(authContext.customer);
            }
            setLoading(false);
        };

        fetchData();
    });

    const saveUtmsToCustomer = (customer: PortalCustomerModel) => {
        let utm_source = localStorage.getItem("utm_source") ?? "";
        let utm_medium = localStorage.getItem("utm_medium") ?? "";
        let utm_campaign = localStorage.getItem("utm_campaign") ?? "";
        let utm_id = localStorage.getItem("utm_id") ?? "";
        if (customer && utm_source) {
            var postModel = {
                customerId: customer.customerId,
                utmCampaign: utm_campaign,
                utmId: utm_id,
                utmMedium: utm_medium,
                utmSource: utm_source,
                emailAddress: customer.customerEmail
            } as UtmTrackingPostRequestModel;
            accountApi
                .accountCustomerUtmTrackingUpdatePost("1", postModel)
                .then((response) => {
                    if (response) {
                        localStorage.removeItem("utm_source");
                        localStorage.removeItem("utm_medium");
                        localStorage.removeItem("utm_campaign");
                        localStorage.removeItem("utm_id");
                        localStorage.removeItem("customer_id");
                    }
                })
                .catch(function (error) { });
        }
    };

    const selectRewardToRedeem = (selectedReward: HomePageRewardListItem) => {
        setRewardToRedeem(selectedReward);
        setShowRedeemPopup(true);
    };

    const redeemModelOnHide = () => {
        setShowRedeemPopup(false);
    };

    if (isLoading) {
        return <></>
    }
    return (
        <React.Fragment>
            <Header parentModel={model} key={authContext?.account?.accountBranding} />

            <main>
                <div className="my_card_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="my_card_box">
                                    <h1>{model.customerName}</h1>
                                    {model.tierName &&
                                        <em className="text-uppercase">
                                            {model.tierName}
                                        </em>
                                    }
                                    <ul className="d-flex align-items-center">
                                        <li>
                                            <div
                                                className="circle_percent"
                                                data-percent={
                                                    model.percentProgressUntilNextReward
                                                }
                                            >
                                                <div className="circle_inner">
                                                    <div className="round_per"></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="may_card_text">
                                                <h2>
                                                    {model.pointsToSpend?.toLocaleString()}
                                                </h2>
                                                <sub>
                                                    {model.pointsLabel} to spend
                                                </sub>
                                                <p>
                                                    {
                                                        model.numPointsUntilNextReward
                                                    }{" "}
                                                    points till you unlock your
                                                    next reward
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                    <a
                                        className="btn btn-orange"
                                        onClick={() => setShowMyCardPopup(true)}
                                    >
                                        Show my card
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {model.availableToRedeemRewardList &&
                    model.availableToRedeemRewardList.length > 0 && (
                        <div className="points_sec_hm">
                            <div className="view">
                                <div className="scrollbox">
                                    <div className="content">
                                        <ul className="content">
                                            {model.availableToRedeemRewardList.map(
                                                (reward, i) => (
                                                    <li
                                                        className="menu-link"
                                                        key={i}
                                                    >
                                                        <div className="points_box">
                                                            <em className="text-uppercase">
                                                                {
                                                                    reward.badgeTitle
                                                                }
                                                            </em>
                                                            <h2>
                                                                {
                                                                    reward.pointsRequired
                                                                }{" "}
                                                                <sub>
                                                                    {
                                                                        reward.pointsLabel
                                                                    }
                                                                </sub>
                                                            </h2>
                                                            <p>
                                                                {
                                                                    reward.rewardTitle
                                                                }{" "}
                                                            </p>
                                                            <a
                                                                onClick={() =>
                                                                    selectRewardToRedeem(
                                                                        reward
                                                                    )
                                                                }
                                                                className="btn btn-orange"
                                                            >
                                                                Redeem
                                                            </a>
                                                            <div className="curve_box"></div>
                                                        </div>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </main>

            <footer className="footer mt-auto">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul>
                                <li className="active">
                                    <a
                                        onClick={() => navigate("/")}
                                        className="btn btn-grey"
                                    >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => navigate("/earn")}
                                        className="btn btn-grey"
                                    >
                                        Earn
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={() => navigate("/redeem")}
                                        className="btn btn-grey"
                                    >
                                        Redeem
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>

            <MyCardModalComponent
                show={showMyCardPopup}
                onHide={() => setShowMyCardPopup(false)}
            />
            {rewardToRedeem && (
                <RedeemModalComponent
                    show={showRedeemPopup}
                    onHide={redeemModelOnHide}
                    reward={rewardToRedeem}
                    membershipId={model.membershipId}
                />
            )}
            <JQueryCirclePercentCode backgroundBannerColor={branding?.bannerBackgroundColor}></JQueryCirclePercentCode>
        </React.Fragment>
    );
};

export default HomePage;

import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
    AccountApi, PortalCustomerModel, UpdateMarketingPreferencesPayload
} from "../../apiclient";
import { apiConfig } from "../../config";
import NotyfContext from "../../contexts/NotyfContext";

import Header from "../../components/Header";
import { AuthContext } from "../../contexts/AccountContext";

const NotificationsPage = (props: any) => {
    let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl);

    const authContext = useContext(AuthContext);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const notyf = useContext(NotyfContext);
    const [inputs, setInputs] = useState({} as UpdateMarketingPreferencesPayload);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer) {
                setModel(authContext.customer);

                setInputs({
                    disableAllNotifications: !authContext.customer.subscribedNotifications,
                    optedOutSmsMarketing: !authContext.customer.subscribedSms,
                    optedOutEmailMarketing: !authContext.customer.subscribed
                });
            }
        };

        fetchData();
    }, []);

    const handleChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
        setInputs((values: UpdateMarketingPreferencesPayload) => ({
            ...values,
            [name]: value,
        }));
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {

            accountApi
                .accountUpdateMarketingPreferencesPost("1", {
                    optedOutSmsMarketing: inputs.optedOutSmsMarketing ?? false,
                    optedOutEmailMarketing:
                        inputs.optedOutEmailMarketing ?? false,
                    disableAllNotifications:
                        inputs.disableAllNotifications ?? false,
                } as UpdateMarketingPreferencesPayload)
                .then((response) => {
                    notyf.open({
                        type: "success",
                        message: "Marketing preferences have been saved",
                        duration: 2500,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });

                    setValidated(false);
                })
                .catch(function (error) {
                    notyf.open({
                        type: "error",
                        message: "Marketing preferences have not been saved",
                        duration: 2500,
                        ripple: true,
                        dismissible: true,
                        position: {
                            x: "right",
                            y: "top",
                        },
                    });
                });
        } else {
            setValidated(true);
        }
    };

    if (!model) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Header parentModel={model} />
            <main>
                <div className="earn_top_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Update Your Notification Settings</h1>
                                <p>
                                    <div>Email address: {model.customerEmail}</div>
                                    {model.customerMobile && <div>Mobile: {model.customerMobile}</div>}
                                </p>
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmit}
                                >
                                    <Row>
                                        <Form.Group className="mt-20">
                                            <Form.Check
                                                id="chkDisableAllNotifications"
                                                name="disableAllNotifications"
                                                checked={
                                                    inputs.disableAllNotifications
                                                }
                                                onChange={handleChange}
                                                label="Unsubscribe from loyalty program emails"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mt-20">
                                            <Form.Check
                                                id="chkOptedOutEmailMarketing"
                                                name="optedOutEmailMarketing"
                                                checked={
                                                    inputs.optedOutEmailMarketing
                                                }
                                                onChange={handleChange}
                                                label="Unsubscribe from email marketing"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mt-20">
                                            <Form.Check
                                                id="chkOptedOutSmsMarketing"
                                                name="optedOutSmsMarketing"
                                                checked={
                                                    inputs.optedOutSmsMarketing
                                                }
                                                onChange={handleChange}
                                                label="Unsubscribe from SMS marketing"
                                            />
                                        </Form.Group>
                                        <Row className="mt-20">
                                            <Col>
                                                <Button type="submit" className="btn btn-orange">Save preferences</Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
};

export default NotificationsPage;

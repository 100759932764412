import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom";

import {
    EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LineIcon, LineShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton
} from "react-share";
import { PortalCustomerModel } from "../../apiclient";
import { AuthContext } from "../../contexts/AccountContext";
import NotyfContext from "../../contexts/NotyfContext";
import closeIcon from "../../images/close-icon.svg";
import emojihands from "../../images/emoji_hands.png";



const style = {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};


const ReferralModalComponent = (props: any) => {

    const [isLoading, setLoading] = useState(true as boolean);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [referralLink, setReferralLink] = useState('' as string);

    const handleClose = () => setShow(false);
    const [model, setModel] = useState({} as PortalCustomerModel);
    const authContext = useContext(AuthContext)
    const notyf = useContext(NotyfContext);

    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer != null) {
                setModel(authContext.customer);
                setReferralLink(model?.referralLink || "");
            }
        };

        fetchData();
    }, []);

    // const copyToClipboard = () =>{
    //     const el = textInput;
    //     el.current.focus();
    //     el.current.select();
    //     document.execCommand("copy");
    //     notyf.open({
    //         type: "success",
    //         message: "Copied to clipboard",
    //         duration: 2500,
    //         ripple: true,
    //         dismissible: true,
    //         position: {
    //             x: "right",
    //             y: "top",
    //         },
    //     });
    // }

    return (
        <React.Fragment>

            <Modal className="referral_popup" show={props.show} onHide={props.onHide}>

                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onHide}><img src={closeIcon} alt="" /></button>
                            <div className="referral_content">
                                <img src={emojihands} alt="" />
                                <h2>Earn {model.referralPointsToEarn} {model.pointsLabel}</h2>

                                <p>Each time a friend signs up through your referral link, they'll get {model.referredRewardTitle}, and you'll receive {model.referralPointsToEarn} {model.pointsLabel}!</p>
                                <div className="referral_form">
                                    <form>
                                        <div className="form-group">
                                            <input type="text" className="form-control" value={model.referralLink} />

                                            {<CopyToClipboard text={model.referralLink ?? ""} onCopy={() => notyf.open({ type: "success", message: "Copied to clipboard", duration: 2500 })}>
                                                <input type="button" value=' ' />
                                            </CopyToClipboard>}
                                        </div>
                                    </form>
                                </div>
                                <em>Copy and paste this link to share with friends anywhere</em>
                                {model.referralLink && <div>
                                    <ul>
                                        <li>
                                            <FacebookShareButton url={model.referralLink} quote={model.referralSocialShareText}>
                                                <FacebookIcon size={40} round />
                                            </FacebookShareButton>
                                        </li>
                                        <li>
                                            <TwitterShareButton url={model.referralLink} title={model.referralSocialShareText}>
                                                <TwitterIcon size={40} round />
                                            </TwitterShareButton>
                                        </li>
                                        {/* <li>
                                            <FacebookMessengerShareButton url={model.referralLink} appId="">
                                                <FacebookMessengerIcon size={40} round />
                                            </FacebookMessengerShareButton>
                                        </li> */}
                                        <li>
                                            <WhatsappShareButton url={model.referralLink} title={model.referralSocialShareText} separator=":: ">
                                                <WhatsappIcon size={40} round />
                                            </WhatsappShareButton>
                                        </li>
                                        <li>
                                            <EmailShareButton url={model.referralLink} subject={model.referralSocialShareText}>
                                                <EmailIcon size={40} round />
                                            </EmailShareButton>
                                        </li>
                                        <li>
                                            <LineShareButton url={model.referralLink} title={model.referralSocialShareText}>
                                                <LineIcon size={40} round />
                                            </LineShareButton>
                                        </li>
                                    </ul>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </React.Fragment>
    );
};

export default ReferralModalComponent;

import AccountLayout from "./layouts/AccountLayout";
import AppLayout from "./layouts/AppLayout";
import InstorePage from "./pages/Account/InstorePage";
import NotificationsPage from "./pages/Account/NotificationsPage";
import Page404 from "./pages/Account/Page404";
import VerifyPage from "./pages/Account/VerifyPage";
import CustomersProfilePage from "./pages/Customer/CustomersProfilePage/CustomersProfilePage";
import CompleteProfilePage from "./pages/Earn/CompleteProfileModalComponent";
import EarnPage from "./pages/Earn/EarnPage";
import HomePage from "./pages/Home/HomePage";
import ReceiptPage from "./pages/Receipt/ReceiptPage";
import ReceiptsPage from "./pages/Receipt/ReceiptsPage";
import RedeemPage from "./pages/Redeem/RedeemPage";



const routes = [
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "",
                element: <HomePage />,
            },
            {
                path: "/home",
                element: <HomePage />,
            },
            {
                path: "/earn",
                element: <EarnPage />,
            },
            {
                path: "/redeem",
                element: <RedeemPage />,
            },

            {
                path: "/completeprofile",
                element: <CompleteProfilePage />,
            },
            {
                path: "/notifications",
                element: <NotificationsPage />,
            },
            {
                path: "/receipts",
                element: <ReceiptsPage />,
            }
        ],
    },
    {
        path: "/receipt/:id",
        element: <AccountLayout><ReceiptPage /></AccountLayout>
    },
    {
        path: "/instore",
        element: <InstorePage />,
    },
    {
        path: "/verify/:token",
        element: <VerifyPage />,
    },
    {
        path: "/pos/kseries/",
        element: <AccountLayout />,
        children: [
            {
                path: "",
                element: <CustomersProfilePage />
            }
        ]
    },
    {
        path: "*",
        element: <Page404 />,
    },
];

export default routes;

import React, { useContext, useEffect, useState } from "react";

const Page404 = () => {
    

    return (
        <React.Fragment>
            <div className="text-center mt-2">
                <div className="display-1">404</div>
                <div className="text-muted">Page not found</div>
                <a className="btn btn-primary mt-2" href="/">Back to app</a>
            </div>
        </React.Fragment>
    );
};

export default Page404;

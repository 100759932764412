import React, { Component } from 'react';
import { Outlet } from "react-router-dom";

const AccountLayout = ({children}) => {

    return (
        <React.Fragment>
             <div className="wrapper">
                {children}
                <Outlet />
            </div>
        </React.Fragment>
    );
};

export default AccountLayout;
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
//import { DashboardApi, RecentActivityComponentViewModel } from "../../apiclient";
import moment from "moment";
import {
    CustomerModel,
    ReceiptApi,
    ReceiptViewModel
} from "../../apiclient";
import { apiConfig } from "../../config";

import Header from "../../components/Header";
import { AuthContext } from "../../contexts/AccountContext";

const ReceiptsPage = (props: any) => {
    let receiptApi = new ReceiptApi(apiConfig.configuration, apiConfig.baseUrl);

    const authContext = useContext(AuthContext);
    const [model, setModel] = useState({} as CustomerModel);
    const [inputs, setInputs] = useState([] as ReceiptViewModel[]);


    useEffect(() => {
        const fetchData = () => {
            if (authContext.customer) {
                setModel(authContext.customer);

                receiptApi
                    .receiptReceiptsGet()
                    .then((response) => {
                        console.log(response.data);
                        setInputs(response.data);
                    });
            }
        };

        fetchData();
    }, []);

    const RenderReceiptListItem = (option: ReceiptViewModel) =>{
        if(option.provider === "Kounta"){
            return (
                    <a href={`/Receipt/ReceiptPdf?orderId=${option.sourceOrderId}`} className="list-group-item list-group-item-action" aria-current="true">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">#{option.title}</h5>
                      <small>Total: ${option.total?.toLocaleString()}</small>
                    </div>
                    <p className="mb-1">{moment
                                .utc(option.orderDateAt)
                                .local()
                                .format("MMMM Do YYYY, h:mm:ss A z")}</p>
                  </a>
            );
        }
        else{
            return (
                <>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">#{option.title}</h5>
                      <small>Total: ${option.total?.toLocaleString()}</small>
                    </div>
                    <p className="mb-1">{moment
                                .utc(option.orderDateAt)
                                .local()
                                .format("MMMM Do YYYY, h:mm:ss A z")}</p>
                    </>
            );
        }
    };

    if (!model) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" />
            </div>
        );
    }

    return (
        <React.Fragment>
            <Header parentModel={model} />
            <main>
                <div className="earn_top_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Receipts</h1>
                                {inputs && inputs.map((option:ReceiptViewModel, i:number) =>(

                        <div className="list-group" key={"earn"+i}>
                            {RenderReceiptListItem(option)}
                        </div>
                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    );
};

export default ReceiptsPage;

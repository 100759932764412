import React, { useContext, useState } from "react";
import { Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { ArrowRight } from "react-feather";
import { useParams } from "react-router-dom";
import NotyfContext from "../../../contexts/NotyfContext";

import { CustomerApi } from "../../../apiclient";
import { apiConfig } from '../../../config';
let customersApi = new CustomerApi(apiConfig.configuration, apiConfig.baseUrl);

const ManualAdjustmentModalComponent = (props: any) => {
    let { customerId } = useParams();
    //modal + form
    const notyf = useContext(NotyfContext);
    const [inputs, setInputs] = useState(
        {} as any
    );
    const [validated, setValidated] = useState(false);
    const [newPointsBalance, setNewPointsBalance] = useState(
        props.pointsBalance || 0
    );

    const handleChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
        // setInputs((values: CreateManualAdjustmentViewModel) => ({
        //     ...values,
        //     [name]: value,
        // }));
        if (name == "pointsToGive") {
            const newBalance =
                parseInt(target.value) + parseInt(props.pointsBalance);


            setNewPointsBalance(newBalance);
        }
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();



        if (form.checkValidity() === true) {
            props.onHide();

            //points points adjustment
            // customersApi.customerCustomersProfilePagePerformManualPointsAdjustmentPost("1", {
            //     comment: inputs.comment ?? "",
            //     pointsToGive: inputs.pointsToGive,
            //     sendEmail: inputs.sendEmail,
            //     customerId: customerId,
            // })
            //     .then((response) => {
            //         if (response) {
            //             notyf.open({
            //                 type: "success",
            //                 message: "Points have been applied",
            //                 duration: 2500,
            //                 ripple: true,
            //                 dismissible: true,
            //                 position: {
            //                     x: "right",
            //                     y: "top",
            //                 },
            //             });
            //             setInputs(
            //                 {} as CreateManualAdjustmentViewModel
            //             ); // clear model
            //             props.updatePointsBalance(newPointsBalance);
            //             setValidated(false);
            //             props.onHide(); // hide modal
            //         }
            //     })
            //     .catch(function (error) {

            //         notyf.open({
            //             type: "error",
            //             message: "Points have not been applied",
            //             duration: 2500,
            //             ripple: true,
            //             dismissible: true,
            //             position: {
            //                 x: "right",
            //                 y: "top",
            //             },
            //         });
            //     });
        } else {
            setValidated(true);
        }
    };

    const modalLoaded = () => {
        setNewPointsBalance(props.pointsBalance);
    };

    return (
        <React.Fragment>
            <Modal
                show={props.isOpen}
                onHide={props.onHide}
                onEntered={modalLoaded}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Adjust {props.firstname}'s points balance
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="h1">
                            <span className="text-muted">
                                {props.pointsBalance &&
                                    (
                                        props.pointsBalance as number
                                    ).toLocaleString()}
                                <small> pts</small>
                            </span>
                            <span className="mh-10">
                                <ArrowRight />
                            </span>
                            <span>
                                {newPointsBalance &&
                                    newPointsBalance.toLocaleString()}{" "}
                                <small> pts</small>
                            </span>
                        </div>
                        <Row>
                            <Form.Group className="mt-20">
                                <Form.Label>Points adjustment</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    name="pointsToGive"
                                    value={inputs.pointsToGive || ""}
                                    onChange={handleChange}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Points required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mt-20">
                                <Form.Check
                                    id="chkSendEmail"
                                    name="sendEmail"
                                    checked={inputs.sendEmail}
                                    onChange={handleChange}
                                    label="Send points notification email to customer?"
                                />
                            </Form.Group>
                            <Form.Group className="mt-20">
                                <Form.Label>
                                    Include message to the customer in points
                                    email:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="e.g. For being a loyal customer"
                                    name="comment"
                                    value={inputs.comment || ""}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={props.onHide}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Apply points adjustment
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default ManualAdjustmentModalComponent;
